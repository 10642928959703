const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import ("@/layouts/Blank"),
        children: [
            {
                path: "/login",
                name: "login",
                meta: {
                    title: "Login"
                },
                component: () => import ("@/views/Login"),
            },
            {
                path: "/recover-password",
                name: "recover_password",
                meta: {
                    title: "Recuperar Contraseña"
                },
                component: () => import ("@/views/RecoverPassword"),
            },
        ]
    },
    {
        path: "/home",
        name: "home",
        redirect: "/dashboard",
        meta: {
            title: "Inicio"
        },
        component: () => import ("@/layouts/Base"),
        children: [
             {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: "Dashboard"
                },
                component: () => import ("@/views/Dashboard")
            },
            {
                path: "/appointments",
                name: "appointments",
                meta: {
                    title: "Citas"
                },
                component: () => import ("@/views/Appointment")
            },
            {
                path: "/payments",
                name: "payments",
                meta: {
                    title: "Compras"
                },
                component: () => import ("@/views/Payment")
            },
            {
                path: "/payments-report",
                name: "payments_report",
                meta: {
                    title: "Rerporte de Ventas"
                },
                component: () => import ("@/views/PaymentReport")
            },
            {
                path: "/cotizations",
                name: "cotizations",
                meta: {
                    title: "Cotizaciones"
                },
                component: () => import ("@/views/Cotization")
            },
            {
                path: "/surveyclient",
                name: "cliente_encuesta",
                meta: {
                    title: "Encuesta - Cliente"
                },
                component: () => import ("@/views/SurveyClient")
            },
            {
                path: "/notifications",
                name: "notifications",
                meta: {
                    title: "Notificaciones"
                },
                component: () => import ("@/views/Notification")
            },
            {
                path: "/manage/clienttype",
                name: "manage_clienttype",
                meta: {
                    title: "Administrar tipo cliente"
                },
                component: () => import ("@/views/ClientType")
            },
            {
                path: "/manage/state",
                name: "manage_state",
                meta: {
                    title: "Administrar estado"
                },
                component: () => import ("@/views/State")
            },
            {
                path: "/manage/city",
                name: "manage_city",
                meta: {
                    title: "Administrar ciudad"
                },
                component: () => import ("@/views/City")
            },
            {
                path: "/manage/client",
                name: "manage_client",
                meta: {
                    title: "Administrar cliente"
                },
                component: () => import ("@/views/Client")
            },
            {
                path: "/manage/car",
                name: "manage_car",
                meta: {
                    title: "Administrar carro"
                },
                component: () => import ("@/views/Car")
            },
            {
                path: "/manage/version",
                name: "manage_version",
                meta: {
                    title: "Administrar version"
                },
                component: () => import ("@/views/Version")
            },
            {
                path: "/manage/model",
                name: "manage_model",
                meta: {
                    title: "Administrar modelo"
                },
                component: () => import ("@/views/Model")
            },
            {
                path: "/manage/vehicle",
                name: "manage_vehicle",
                meta: {
                    title: "Administrar vehiculo"
                },
                component: () => import ("@/views/Vehicle")
            },
            {
                path: "/manage/survey",
                name: "manage_survey",
                meta: {
                    title: "Administrar encuesta"
                },
                component: () => import ("@/views/Survey")
            },
            {
                path: "/manage/question",
                name: "manage_question",
                meta: {
                    title: "Administrar pregunta"
                },
                component: () => import ("@/views/Question")
            },
            {
                path: "/manage/family",
                name: "manage_family",
                meta: {
                    title: "Administrar familia"
                },
                component: () => import ("@/views/Family")
            },
            {
                path: "/manage/part",
                name: "manage_part",
                meta: {
                    title: "Administrar parte"
                },
                component: () => import ("@/views/Part")
            },
            {
                path: "/manage/servicetype",
                name: "manage_servicetype",
                meta: {
                    title: "Administrar tipo servicio"
                },
                component: () => import ("@/views/ServiceType")
            },
            {
                path: "/manage/package",
                name: "manage_package",
                meta: {
                    title: "Administrar paquete"
                },
                component: () => import ("@/views/Package")
            },
            {
                path: "/manage/branch",
                name: "manage_branch",
                meta: {
                    title: "Administrar sucursal"
                },
                component: () => import ("@/views/Branch")
            },
            {
                path: "/manage/service",
                name: "manage_service",
                meta: {
                    title: "Administrar servicio"
                },
                component: () => import ("@/views/Service")
            },
            {
                path: "/manage/advisor",
                name: "manage_advisor",
                meta: {
                    title: "Administrar agente"
                },
                component: () => import ("@/views/Advisor")
            },
            {
                path: "/manage/promotiontype",
                name: "manage_promotiontype",
                meta: {
                    title: "Administrar tipo promocion"
                },
                component: () => import ("@/views/PromotionType")
            },
            {
                path: "/manage/promotion",
                name: "manage_promotion",
                meta: {
                    title: "Administrar promocion"
                },
                component: () => import ("@/views/Promotion")
            },
            {
                path: "/manage/holiday",
                name: "manage_holiday",
                meta: {
                    title: "Administrar feriado"
                },
                component: () => import ("@/views/Holiday")
            },
            {
                path: "/manage/socialreason",
                name: "manage_socialreason",
                meta: {
                    title: "Administrar razon social"
                },
                component: () => import ("@/views/SocialReason")
            },
            {
                path: "/manage/comercialactivity",
                name: "manage_comercialactivity",
                meta: {
                    title: "Administrar actividad comercial"
                },
                component: () => import ("@/views/ComercialActivity")
            },
            {
                path: "/manage/businessactivity",
                name: "manage_businessactivity",
                meta: {
                    title: "Administrar tipo de actividad empresarial"
                },
                component: () => import ("@/views/BusinessActivity")
            },
            {
                path: "/manage/cfdi",
                name: "manage_cfdi",
                meta: {
                    title: "Administrar CFDI"
                },
                component: () => import ("@/views/CFDI")
            },
            {
                path: "/manage/action",
                name: "manage_action",
                meta: {
                    title: "Administrar Acción"
                },
                component: () => import ("@/views/Action")
            },
            {
                path: "/profile/client/:id(\\d+)",
                name: "profile_client",
                meta: {
                    title: "Perfil cliente"
                },
                component: () => import ("@/views/Profile")

            },
            {
                path: "/vehicles/client/:id(\\d+)",
                name: "my_vehicles",
                meta: {
                    title: "Mis vehículos"
                },
                component: () => import ("@/views/MyVehicles")
            },
            {
                path: "/detail/part/:id(\\d+)",
                name: "detail_part",
                meta: {
                    title: "Detalle parte"
                },
                component: () => import ("@/views/DetailPart")
            },
            {
                path: "/detail/service/:id(\\d+)",
                name: "detail_service",
                meta: {
                    title: "Detalle servicio"
                },
                component: () => import ("@/views/DetailService")
            },
            {
                path: "/detail/promocion/:id(\\d+)",
                name: "detail_promotion",
                meta: {
                    title: "Detalle promocion"
                },
                component: () => import ("@/views/DetailPromotion")
            },
            {
                path: "/detail/notification/:id(\\d+)",
                name: "detail_notification",
                meta: {
                    title: "Detalle notificacion"
                },
                component: () => import ("@/views/DetailNotification")
            },
            {
                path: "/detail/action/:id(\\d+)",
                name: "detail_action",
                meta: {
                    title: "Detalle accion"
                },
                component: () => import ("@/views/DetailAction")
            },
            {
                path: "/versionmovilapp",
                name: "manage_versionmovilapp",
                meta: {
                    title: "Administrar versión app móvil"
                },
                component: () => import ("@/views/VersionMovilApp")
            },
            /*
            {
                path: "/manage/versionmovilapp",
                name: "manage_versionmovilapp",
                meta: {
                    title: "Administrar versión app móvil"
                },
                component: () => import ("@/views/VersionMovilApp")
            },
            */
        ]
    },
    {
        path: "*",
        meta: {
            title: "Error 404"
        },
        component: () => import ("@/views/404"),
    }
];

export default routes;
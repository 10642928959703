 function initialState() {
  return {
      Sidebar_drawer: null,
      Customizer_drawer: false,
      SidebarColor: "#1d2228", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
      SidebarBg: "",
      navbarColor: "white",
      setHorizontalLayout: false, // Horizontal layout
      profile: {},
      notifList: [], 
      notif_resp: [],
      client_list: [],
      client_role_list: [],
      jwt: null
  };  
}

export default initialState;
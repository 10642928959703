import initialState from './initial_state';

const mutations = {
    SET_SIDEBAR_DRAWER(state, payload) {
        state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
        state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
        state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
        state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
        state.setHorizontalLayout = payload;
    },
    SET_JWT(state, payload) {
        state.jwt = payload.jwt;
    },
    SET_JWT_VALID(state, payload) {
        state.isJWTValid = payload.valid;
    },    
    SET_DATA_PROFILE(state, payload) {
        state.profile = payload;
    }, 
    SET_NOTIF_LIST(state, payload) {
        state.notifList = payload;
    },    
    SET_NOTIF_RESP(state, payload) {
        state.notif_resp = payload;
    },    
    SET_CLIENT_LIST(state, payload) {
        state.client_list = payload;
    },   
    SET_CLIENT_ROLE_LIST(state, payload) {
        state.client_role_list = payload;
    },       
    RESET_STATE(state) {
        Object.assign(state, initialState());
    },                        
};

export default mutations;
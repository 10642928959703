import API from '../api/base';
import getParamQuery from '../queries/param_query.js';

const actions = {
    notifList({dispatch, commit, getters}) {
        dispatch("execGraphQLQueryParam", {
            query: getParamQuery(
                "notificationProfileList",
                "$toClient: Int!",
                "toClient: $toClient",
                `results {                    
                    id
                    title
                    text
                    read
                    type                        
                    created                     
                }                
                `
            ),
            variables: {
                toClient: getters.getProfile.id
            }
        })
            .then(_response => {
                if (_response.status === 200 && _response.data.data.notificationProfileList.results.length > 0) {
                    commit('SET_NOTIF_LIST', _response.data.data.notificationProfileList.results);
                } else {
                    commit('SET_NOTIF_LIST', []);
                }
            });
        //    if(!(getters.getProfile.is_superuser) && ((Object.keys(getters.getProfile).indexOf("role") !== -1 ? getters.getProfile.role : "ADMIN") !== "ADMIN")) {
        //         dispatch("execGraphQLQueryParam", {
        //             query: getParamQuery(
        //                 "notificationProfileList",
        //                 "$toClient: Int!, $pageSize: Int",
        //                 "toClient: $toClient, pageSize: $pageSize",
        //                 `results {
        //                     id
        //                     title
        //                     text
        //                     read
        //                     type
        //                     created
        //                     fromClient {
        //                         urlPicture
        //                         user {
        //                             firstName
        //                             lastName
        //                             email
        //                         }
        //                     }
        //                     toClient {
        //                         urlPicture
        //                         user {
        //                             firstName
        //                             lastName
        //                             email
        //                         }
        //                     }
        //                 }
        //                 `
        //             ),
        //             variables: {
        //                 toClient: getters.getProfile.id,
        //                 pageSize: 6
        //             }
        //         })
        //         .then(_response => {
        //             if(_response.status == 200 && _response.data.data.notificationProfileList.results.length > 0) {
        //                 commit('SET_NOTIF_LIST', _response.data.data.notificationProfileList.results);
        //             }
        //             else {
        //                 commit('SET_NOTIF_LIST', []);
        //             }
        //         });
        //    }
        //    else {
        //         dispatch("execGraphQLQueryParam", {
        //             query: getParamQuery(
        //                 "notificationList",
        //                 "$pageSize: Int",
        //                 "pageSize: $pageSize",
        //                 `results {
        //                     id
        //                     title
        //                     text
        //                     read
        //                     type
        //                     created
        //                     fromClient {
        //                         urlPicture
        //                         user {
        //                             firstName
        //                             lastName
        //                             email
        //                         }
        //                     }
        //                     toClient {
        //                         urlPicture
        //                         user {
        //                             firstName
        //                             lastName
        //                             email
        //                         }
        //                     }
        //                 }
        //                 `
        //             ),
        //             variables: {
        //                 pageSize: 6
        //             }
        //         })
        //         .then(_response => {
        //             if(_response.status == 200 && _response.data.data.notificationList.results.length > 0) {
        //                 commit('SET_NOTIF_LIST', _response.data.data.notificationList.results);
        //             }
        //             else {
        //                 commit('SET_NOTIF_LIST', []);
        //             }
        //         });
        //    }
    },

    sendPushNotification({dispatch, commit, getters}, {clients, title, text, type, automatic}) {
        dispatch("execGraphQLQueryParam", {
            query: getParamQuery(
                "sendPushNotificationToClientList",
                "$fromClient: Int!, $toClient: [Int], $notifType: String!, $title: String!, $text: String!, $automatic: Boolean",
                "fromClient: $fromClient, toClient: $toClient, notifType: $notifType, title: $title, text: $text, automatic: $automatic",
                `results {
                    client
                    sent
                }`
            ),
            variables: {
                fromClient: getters.getProfile.id,
                toClient: clients,
                notifType: type,
                title: title,
                text: text,
                automatic: automatic
            }
        })
            .then(response => {
                console.info('push notification response: ', response);
                if (response.status === 200) {
                    commit('SET_NOTIF_RESP', response.data.data.sendPushNotificationToClientList.results);
                } else {
                    commit('SET_NOTIF_RESP', []);
                }
            });
    },

    async clientRoleList({commit}, {roles}) {
        const resp = await API.post('graphql/', {
            query: `query {
                        profileRoleList(roles: ${JSON.stringify(roles)}, pageSize: 1000, page: 1) {
                            results {
                                id
                            } 
                }
        }`
        });
        commit('SET_CLIENT_ROLE_LIST', resp.data.data.profileRoleList.results);
    },

    async execGraphQLQuery(context, {query}) {
        try {
            const resp = await API.post('graphql/', {
                query: query
            });
            return resp;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    },

    async execGraphQLQueryParam(context, {query, variables}) {
        try {
            const resp = await API.post('graphql/', {
                query: query,
                variables: variables
            });
            return resp;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    },

};

export default actions;
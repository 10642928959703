import Vue from "vue";
import Router from "vue-router";
import Routes from './routes';
import Store from '../store/index';
import goTo from "vuetify/es5/services/goto";
import API from '../api/base';

Vue.use(Router);

const router = new Router({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: [...Routes],
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    }
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + " | NISSAN";
    });
});

const isAuthenticated = async () => {
  if(Store.getters.getJWT != null) {
    try {
          const resp = await API.get('api/isjwtvalid/', { 
                                    headers: {'Authorization': `Token ${Store.getters.getJWT}`} 
                            });
          return resp.status === 200 ? true : false;                         
    }
    catch(error) {
        console.log(error);
        return false;
    } 
  }  
  else {
    return false;
  } 
};

router.beforeEach((to, from, next) => {
    if (to.name !== 'login') {
       isAuthenticated().then(auth => {
          if(auth) {
              Store.dispatch('notifList');
              next();
          }
          else {
             if (to.name === "recover_password") {
                next();
             } else {
                next({ name: 'login' });
             }
          }
       });
    }
    else if(to.name === 'login') {
       isAuthenticated().then(auth => {
          auth ? next({ name: 'home' }) : next();
       });
    }
});

export default router;
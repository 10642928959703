import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/index";
import store from "./store/index";
import VueMeta from 'vue-meta';
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import moment from "moment";
import getDateHM from "./utils/date_hm.js";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Vue.use(VueFeather);
Vue.use(VueSkycons, { color: "#1e88e5" });
Vue.use(InstantSearch);
Vue.use(Vuebar);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

Vue.config.productionTip = false;

Vue.filter('formatDateTime', function(value) {
   if(value) {
       return moment(String(value)).format('DD/MM/YYYY | HH:mm');
   }
});

Vue.filter('formatDateTimeAppointment', function(value) {
   if(value) {
       return moment(getDateHM(String(value))).format('DD/MM/YYYY | HH:mm');
   }
});

Vue.filter('formatDate', function(value) {
   if(value) {
       return moment(String(value)).format('DD/MM/YYYY');
   }
});

Vue.filter('timeAgo', function(value) {
   if(value) {
       return moment(String(value), "YYYY-MM-DD HH:mm:ss").fromNow();
   }
});

Vue.filter('float', function(value) {
    if(value) {
        return value.toFixed(2);        
    }
});

Vue.filter('thousands', function(value) {
    if(value) {
        return value.toLocaleString('en-US', {minimumFractionDigits: 2});    
    }
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

Sentry.init({
  dsn: 'https://56fce017613d4cd19f3e9f0fc54d6054@sentry.idooproject.com/30',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});
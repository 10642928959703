const getters = {
    getJWT(state) {
        return state.jwt;
    },    
    getProfile(state) {
        return state.profile;
    },           
    getNotifList(state) {
        return state.notifList;    
    }, 
    getNotifResp(state) {
        return state.notif_resp;    
    },      
    getClientList(state) {
        return state.client_list.map(profile => profile.id);      
    },    
    getClientRoleList(state) {
        return state.client_role_list.map(profile => profile.id);      
    },     
    getTNotifUnRead(state) {
        return  state.notifList.length ? state.notifList.filter(notif => !notif.read).length : 0;
    }              
};

export default getters;